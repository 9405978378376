export enum StoreEnum {
  Categories = 'categories',
  Companies = 'companies',
  Documents = 'documents',
  IncidentObservations = 'incidentObservations',
  IncidentReasons = 'incidentReasons',
  IncidentWorkflows = 'incidentWorkflows',
  Incidents = 'incidents',
  Persons = 'persons',
  ProjectTypes = 'projectTypes',
  Projects = 'projects',
  QaDocumentBooks = 'qaDocumentBooks',
  QaDocumentLifespans = 'qaDocumentLifespans',
  QaDocumentWorkflows = 'qaDocumentWorkflows',
  QaDocuments = 'qaDocuments',
  Registrations = 'registrations',
  Tasks = 'tasks', // stores MyTasks with OPEN status
  UsersPicker = 'usersPicker'
}
