import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IAction, IIBricksEntity, IResponse} from '../interfaces';
import {StoreEnum} from '../enums/store-enum';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})

/**
 * This is a fake dbService. It's methods return empty results to allow depended on logic to continue working.
 * TODO replace this service with db-service from ./db-service-backup folder.
 * TODO remove "src/app/modules/shared/services/db.service.ts" from ignorePatterns of .eslintrc.json file.
 */
export class DBService {
  constructor(private storageService: StorageService) {}

  public getEntityByUnid<T>(storeName: string, unid: string, fieldName?: string): Observable<IResponse<T> | null> {
    return of(null);
  }

  public getAll<T>(storeName: string): Observable<T[]> {
    return of([]);
  }

  public getAllBy<T>(storeName: string, fieldName: string, fieldValue: string): Observable<T[]> {
    return of([]);
  }

  public update<T>(storeName: string, value?: T): Observable<T | null> {
    return of(value);
  }

  public delete<T>(storeName: string, key?: string): Observable<T[] | null> {
    return of(null);
  }

  public bulkPut<T>(storeName: string, values: T[]): Observable<T[]> {
    return of(values);
  }

  public bulkPutResponse<T>(storeName: string, res: IResponse<T[]>): Observable<IResponse<T[]>> {
    return of(res);
  }

  public bulkDeleteAction(storeName?: string, action?: IAction): Observable<IAction | null> {
    return of(action || null);
  }

  public bulkPutByUnidListAction<T>(
    storeName: string,
    obj: Partial<T>,
    action?: IAction,
    merge?: boolean
  ): Observable<IAction> {
    return of(action);
  }

  private getStoreSyncTasks(storeName: string): Observable<IIBricksEntity[]> {
    return of([]);
  }

  public getSyncTasks(): Observable<IIBricksEntity[]> {
    this.storageService.removeOldSyncTasks();
    return of([]);
  }

  public clearStoreEntities(storeName: string): Observable<boolean> {
    return of(false);
  }

  public clearStores(stores: StoreEnum[]): Observable<boolean[]> {
    return of([false]);
  }

  private entitiesFilter<T>(data: T[]): T[] {
    return [];
  }
}
